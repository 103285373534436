import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  byUser,
  remove,
  save,
  update
} from '../../../helpers/actions/documents';

import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../../components/index';
import DocumentCard from './DocumentCard';
import DocumentModal from './DocumentModal';
import { documentStatus } from '../../../helpers/nomenclators';
import Select from 'react-select';

class Pros extends Component {
  state = {
    loading: true,
    data: [],
    count: 0,
    page: 1,
    limit: 18,
    search: '',
    status: '',
    document: null,
    documentDelete: null
  };

  load() {
    this.setState({ loading: true });

    let { page, limit, search, status } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    Promise.all([dispatch(byUser({ limit, offset, search, status }))])
      .then(([{ data, meta }]) => {
        this.setState({ data, ...meta, loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  onSaveDocument(documentEdit) {
    this.setState({ loading: true, document: null });
    let { dispatch } = this.props;

    let fd = new FormData();
    if (documentEdit.file) {
      fd.append('file', documentEdit.file);
      delete documentEdit.file;
    }
    // eslint-disable-next-line
    for (let key of Object.keys(documentEdit)) {
      fd.append(key, documentEdit[key]);
    }

    if (!documentEdit._id) {
      dispatch(save(fd))
        .then(() => {
          this.load();
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      dispatch(update(documentEdit._id, fd))
        .then(() => {
          this.load();
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  }

  deleteDocument(id) {
    this.setState({ loading: true, documentDelete: null });
    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
      loading,
      data,
      page,
      limit,
      count,
      document,
      documentDelete,
      search,
      status
    } = this.state;
    let { t } = this.props;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className={'text-left'} xs={12} md={5}>
                        <FormGroup>
                          <Label>
                            <Trans>Search</Trans>
                          </Label>
                          <Input
                            type="text"
                            value={search}
                            onChange={event =>
                              this.setState(
                                {
                                  page: 1,
                                  search: event ? event.target.value : null
                                },
                                () => this.load()
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className={'text-left'} xs={12} md={5}>
                        <FormGroup>
                          <Label>
                            <Trans>Status</Trans>
                          </Label>
                          <Select
                            className="primary"
                            options={documentStatus.map(u => ({
                              label: t(u),
                              value: u
                            }))}
                            value={status ? status : ''}
                            onChange={event =>
                              this.setState(
                                { page: 1, status: event ? event.value : '' },
                                () => this.load()
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className={'text-right'} xs={12} md={2}>
                        <Button
                          color="info"
                          onClick={() => this.setState({ document: {} })}
                        >
                          <Trans>Add</Trans>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {data.length > 0 ? (
            <div>
              <Row>
                {data.map((doc, key) => (
                  <Col key={key} xs={12} sm={12} md={6} lg={6}>
                    <DocumentCard
                      document={doc}
                      onEdit={documentEdit =>
                        this.setState({ document: documentEdit })
                      }
                      onDelete={deleteDocument =>
                        this.setState({ documentDelete: deleteDocument })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No documents found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <DocumentModal
            show={!!document}
            size={'lg'}
            document={document}
            closeModal={() => this.setState({ document: null })}
            saveDocument={documentEdit => this.onSaveDocument(documentEdit)}
          />

          {!documentDelete ? null : (
            <AlertConfirm
              message={t('The document cannot be recovered')}
              onCancel={() => this.setState({ documentDelete: null })}
              onConfirm={() => this.deleteDocument(documentDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(state => state)(translate('translations-fr')(Pros));
