import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import { dateFormatter } from '../../../helpers/formatters';

class DocumentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  componentWillReceiveProps() {
    this.setState({ selected: false });
  }

  render() {
    let { selected } = this.state;
    let { document } = this.props;

    return (
      <Card
        className={`card-selectable ${selected ? 'selected' : ''} ${
          document.status === 'expired' ? 'bg-warning' : ''
        }`}
      >
        <CardHeader
          className={'pt-2'}
          onClick={() => this.setState({ selected: !selected })}
        >
          <Row>
            <Col xs={9} sm={9} md={9} lg={9}>
              <h6>{document.userName}</h6>
              <Row>
                <Col xs={4} sm={3} md={3} className={'pr-0'}>
                  <strong>
                    <Trans>Document</Trans>:
                  </strong>
                  <br />
                  <strong>
                    <Trans>Status</Trans>:
                  </strong>
                  <br />
                  <strong>
                    <Trans>Expired</Trans>:
                  </strong>
                  <br />
                  <strong>
                    <Trans>Kind</Trans>:
                  </strong>
                  <br />
                </Col>
                <Col xs={8} sm={9} md={9} className={'pl-0'}>
                  {document.source ? (
                    <a href={document.source} target={'blank'}>
                      {document.name}
                    </a>
                  ) : (
                    document.name
                  )}
                  <br />
                  <Trans>{document.status}</Trans>
                  <br />
                  {dateFormatter(document.expire)}
                  <br />
                  <Trans>{document.kind}</Trans>
                  <br />
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={3}
              className={'text-right pl-1 pr-1'}
            >
              <Button
                id={`edit-${document._id}`}
                className={'btn-icon'}
                color="info"
                size="sm"
                onClick={() => this.props.onEdit(document)}
              >
                <i className="fas fa-pencil-alt icon-action" />
              </Button>{' '}
              <UncontrolledTooltip
                placement="left"
                target={`edit-${document._id}`}
                delay={0}
              >
                <Trans>Edit</Trans>
              </UncontrolledTooltip>
              <Button
                id={`remove-${document._id}`}
                className={'btn-icon'}
                color="danger"
                size="sm"
                onClick={() => this.props.onDelete(document)}
              >
                <i className="fa fa-trash icon-action" />
              </Button>
              <UncontrolledTooltip
                placement="left"
                target={`remove-${document._id}`}
                delay={0}
              >
                <Trans>Delete</Trans>
              </UncontrolledTooltip>
            </Col>
          </Row>
        </CardHeader>
        <CardBody
          className={'text-left pt-0 pb-0'}
          style={{ display: selected ? 'block' : 'none' }}
        >
          <hr />
          <strong>
            <Trans>Description</Trans>:
          </strong>
          <br /> {document.description}
        </CardBody>
      </Card>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(DocumentCard)
);
