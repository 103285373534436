function hexToRGB(hex, alpha) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

const chartColor = '#FFFFFF';

const statusProjectChartOptions = {
  legend: {
    display: false
  }
};

const statusProjectChart = (statusProject, statusColors, t) => {
  return {
    data: canvas => {
      let ctx = canvas.getContext('2d');
      let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, '#18ce0f');
      gradientStroke.addColorStop(1, chartColor);
      let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
      gradientFill.addColorStop(0, 'rgba(128, 182, 244, 0)');
      gradientFill.addColorStop(1, hexToRGB('#18ce0f', 0.4));
      return {
        labels: statusProject.status.map(s => t(s)),
        datasets: [
          {
            label: ` ${t('Earnings')}`,
            backgroundColor: statusColors,
            data: statusProject.counts
          }
        ]
      };
    },
    options: statusProjectChartOptions
  };
};

const earningsMonthsChartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  tooltips: {
    bodySpacing: 4,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
    xPadding: 10,
    yPadding: 10,
    caretPadding: 10
  },
  responsive: 1,
  scales: {
    yAxes: [
      {
        gridLines: {
          zeroLineColor: 'transparent',
          drawBorder: false
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          zeroLineColor: 'transparent',
          drawTicks: false,
          display: false,
          drawBorder: false
        }
      }
    ]
  },
  layout: {
    padding: { left: 0, right: 0, top: 15, bottom: 15 }
  }
};

const earningsMonthsChart = (earningsMonths, t) => {
  return {
    data: canvas => {
      let ctx = canvas.getContext('2d');
      let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, '#18ce0f');
      gradientStroke.addColorStop(1, chartColor);
      let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
      gradientFill.addColorStop(0, 'rgba(128, 182, 244, 0)');
      gradientFill.addColorStop(1, hexToRGB('#18ce0f', 0.4));
      return {
        labels: [
          t('JAN'),
          t('FEB'),
          t('MAR'),
          t('APR'),
          t('MAY'),
          t('JUN'),
          t('JUL'),
          t('AUG'),
          t('SEP'),
          t('OCT'),
          t('NOV'),
          t('DEC')
        ],
        datasets: [
          {
            label: ` ${t('Earnings')}`,
            borderColor: '#18ce0f',
            pointBorderColor: '#FFF',
            pointBackgroundColor: '#18ce0f',
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 1,
            pointRadius: 4,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 2,
            data: earningsMonths
          }
        ]
      };
    },
    options: earningsMonthsChartOptions
  };
};

const projectsMonthsChartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  tooltips: {
    bodySpacing: 4,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
    xPadding: 10,
    yPadding: 10,
    caretPadding: 10
  },
  responsive: 1,
  scales: {
    yAxes: [
      {
        gridLines: {
          zeroLineColor: 'transparent',
          drawBorder: false
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          zeroLineColor: 'transparent',
          drawTicks: false,
          display: false,
          drawBorder: false
        }
      }
    ]
  },
  layout: {
    padding: { left: 0, right: 0, top: 15, bottom: 15 }
  }
};

const projectsMonthsChart = (projectsMonths, t) => {
  return {
    data: canvas => {
      let ctx = canvas.getContext('2d');
      let gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, '#18ce0f');
      gradientStroke.addColorStop(1, chartColor);
      let gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
      gradientFill.addColorStop(0, 'rgba(128, 182, 244, 0)');
      gradientFill.addColorStop(1, hexToRGB('#18ce0f', 0.4));
      return {
        labels: [
          t('JAN'),
          t('FEB'),
          t('MAR'),
          t('APR'),
          t('MAY'),
          t('JUN'),
          t('JUL'),
          t('AUG'),
          t('SEP'),
          t('OCT'),
          t('NOV'),
          t('DEC')
        ],
        datasets: [
          {
            label: ` ${t('Projects')}`,
            borderColor: '#18ce0f',
            pointBorderColor: '#FFF',
            pointBackgroundColor: '#18ce0f',
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 1,
            pointRadius: 4,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 2,
            data: projectsMonths
          }
        ]
      };
    },
    options: projectsMonthsChartOptions
  };
};

module.exports = {
  statusProjectChart,
  earningsMonthsChart,
  projectsMonthsChart
};
