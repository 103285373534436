import React from 'react';
import { connect } from 'react-redux';
import { translate, Trans } from 'react-i18next';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { CardCategory, PanelHeader } from '../../components';
import { Line } from 'react-chartjs-2';
import sum from 'lodash/sum';
import { stats } from '../../helpers/actions/finances';
import { devisPriceTTC } from '../Projects/Devis/Prices';
import { moneyFormatter } from '../../helpers/formatters';
import { earningsMonthsChart } from './charts.jsx';

class Finances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      earningsMonths: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      projectsFinished: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    };
  }

  load() {
    let { dispatch } = this.props;

    dispatch(stats())
      .then(data => {
        let { earningsMonths, projectsFinished } = this.state;

        data.devisYear.forEach(devis => {
          let month = new Date(devis.approved).getMonth();
          let earnings = devisPriceTTC(devis);

          earningsMonths[month] = earningsMonths[month] + earnings;
        });

        data.projectsFinished.forEach(devis => {
          let month = new Date(devis.approved).getMonth();
          let earnings = devisPriceTTC(devis);

          projectsFinished[month] = projectsFinished[month] + earnings;
        });

        this.setState({ data, earningsMonths, projectsFinished });
      })
      .catch(e => {});
  }

  componentWillMount() {
    this.load();
  }

  onStatus(element) {
    let { history } = this.props;

    if (element.length) {
      history.push(
        `/projects/by-status/${
          element[0]._chart.config.data.labels[element[0]._index]
        }`
      );
    }
  }

  render() {
    let { earningsMonths, projectsFinished } = this.state,
      { t } = this.props;

    let earningsChart = earningsMonthsChart(earningsMonths, t);
    let projectsChart = earningsMonthsChart(projectsFinished, t);

    return (
      <div>
        <PanelHeader size="sm" content={<Row />} />
        <div className="content">
          <Row>
            <Col xs={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardCategory>
                    <Trans>Devis approved</Trans>
                  </CardCategory>
                  <CardTitle tag="h2">
                    {moneyFormatter(sum(earningsMonths))}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={earningsChart.data}
                      options={earningsChart.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardCategory>
                    <Trans>Earnings in the year</Trans>
                  </CardCategory>
                  <CardTitle tag="h2">
                    {moneyFormatter(sum(projectsFinished))}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={projectsChart.data}
                      options={projectsChart.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Finances));
